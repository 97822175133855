import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Observable, catchError, finalize, retry, throwError, timer } from 'rxjs';
import { CRON_TIMER_PERIOD, MAX_NUMBER_OF_404_ERRORS } from '../../constants/forecast';
import { LoadingService } from '../../../forecast/services/loading.service';
import { ENDPOINTS } from '../../constants/endpoints';
import { ApplicationError } from './error';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService, private activatedRoute: ActivatedRoute) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let count = 0;
    
    const urlWithRefinery = request.url.replace('catw/v0.0.1/v1','catw/v0.0.1/v1/' + this.activatedRoute.children[0].children[0].snapshot.params['refineryCode']);
    const newRequest = request.clone({url: urlWithRefinery });
 
    if (request.url.endsWith(ENDPOINTS.forecastOutputEndpoint)) {
      count = MAX_NUMBER_OF_404_ERRORS;
      this.loadingService.setRequestProgress(true);
    }
    if (request.url.endsWith(ENDPOINTS.forecastInputEndpoint)) {
      this.loadingService.setRequestProgress(true);
    }

    return next.handle(newRequest).pipe(
      retry({
        count,
        delay: this.iterateOn404,
      }),
      catchError((error) => {
        return throwError(() => this.formatError(error));
      }),
      finalize(() => this.loadingService.setRequestProgress(false)),
    );
  }

  private iterateOn404(error: HttpErrorResponse): Observable<any> {
    if (error.status === HttpStatusCode.NotFound) {
      return timer(CRON_TIMER_PERIOD);
    }
    return throwError(() => error);
  }

  private formatError(error: any): ApplicationError {
    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client side error or network error occured.
        console.error('An error occured', error.error.message);
        return { title: error.statusText, status: error.status, detail: error.error.message };
      }

      // the backend returned an unsuccessful response code.
      console.error(`[HTTP] Backend returned code ${error.status}, ` + `body was: ${JSON.stringify(error.error)}`);
      return { title: error.statusText, status: error.status, detail: error.message };
    }

    console.error(error);
    return {
      title: 'Unknown error',
      status: 0,
      detail: 'Something bad happened; please try again later',
    };
  }
}
