/**
 * Input web Component of type file that take an excel file and transform it to Json object.
 * Input options is an object of sheetToExclude an array of string (sheet you want to exclude them from parse) and defaultValue (default value for empty cell in the excel)
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { read, utils } from 'xlsx';

@Component({
  selector: 'app-ui-import-btn',
  templateUrl: './ui-import-btn.component.html',
  styleUrls: ['./ui-import-btn.component.scss'],
})

/**
 * @class UiImportBtnComponent
 */
export class UiImportBtnComponent {
  @Output() importedData = new EventEmitter<any>();
  @Input() options?: { sheetToExclude: string[]; defaultValue: unknown };

  @Input() isActiveCycle:boolean = true;

  /**
   * @function fileUpload catch DOM event and get the excel file then transform it to binary then call excelToJson to transform it to jsom then call the emit event
   * @param event
   */
  fileUpload(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target.files) {
      const selectedFile = target.files[0];
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        const binaryData: ArrayBuffer = event.target?.result as ArrayBuffer;
        const jsonData = this.excelToJson(binaryData);
        this.importedData.emit(jsonData);
        // reset the input
        target.value = '';
      };
      fileReader.readAsArrayBuffer(selectedFile);
    }
  }

  /**
   * @function excelToJson it transform binary data to json using xlsx
   * @param data ArrayBuffer
   * @returns Json or an error in Sheet1 object
   */
  excelToJson(data: ArrayBuffer) {
    try {
      const workBook = read(data, { type: 'array' });
      const finalSheetNames = workBook.SheetNames.filter((name) => {
        return !this.options?.sheetToExclude.includes(name);
      });
      return finalSheetNames.reduce((initial: any, name: string) => {
        const sheet = workBook.Sheets[name];
        initial[name] = utils.sheet_to_json(sheet, { defval: this.options?.defaultValue });
        return initial;
      }, {});
    } catch (error: any) {
      return new Error(error);
    }
  }
}
