import { Component, OnInit } from '@angular/core';
import { ForecastService } from './services/forecast.service';
import { IForecast } from '../types/forecast';
import { FormArray, FormGroup } from '@angular/forms';
import { ForecastResults, IGraphData } from '../types/forecast-results';
import { LoadingService } from './services/loading.service';
import { ForecastMapperService } from './services/forecast-mapper.service';
import { ForecastFormService } from './services/forecast-form.service';
import { Observable } from 'rxjs';
import { ModalService } from '../shared/services/modal/modal.service';
import { ForecastDataPeriodsService } from './services/forecast-data-periods.service';
import { UtilsService } from '../shared/components/ui-export-btn/utils/utils.service';
import { ActivatedRoute } from '@angular/router';
import { IForecastDisplayPreference } from '../types/forecast-display-preference';
import { ForecastCycle } from '../types/forecast-cycle';

@Component({
  selector: 'app-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.scss'],
})
export class ForecastComponent implements OnInit {
  public inputForecastForm?: FormGroup;

  public validPredictionInputs?: IForecast;
  public predictionResults?: IGraphData;

  public forecastResult?: ForecastResults;
  public cycleLengthInDays = 0;
  public graphNumberTodisplay = 1;
  public cycleLengthDate = '';
  public startRunDate?: IForecastDisplayPreference | null;
  public simultaneousDisplay = false;
  public isActiveCycle = true;
  public catalystCycleId = 0;
  public cycleList: any[] = [];

  public importExcelOption = {
    sheetToExclude: ['OUTPUTS'],
    defaultValue: null,
  };

  public hasRequestInProgress$?: Observable<boolean>;
  public accordionClicked = false;
  // public periodDuration: (number | number[])[] = [];
  public periodDuration: any = [];

  constructor(
    private forecastService: ForecastService,
    private loadingService: LoadingService,
    private forecastMapperService: ForecastMapperService,
    private forecastFormService: ForecastFormService,
    private modalService: ModalService,
    private forecastDataPeriodsService: ForecastDataPeriodsService,
    private utilsExportService: UtilsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.forecastService.getSelectedCycle().subscribe((cycle:ForecastCycle | null)=> {
      if(cycle) {
        this.isActiveCycle = cycle.active;
        this.catalystCycleId = cycle.catalystCycleId;        
        this.fetchLatestInputOutput(cycle.catalystCycleId);
      }      
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(() => {      
      this.hasRequestInProgress$ = this.loadingService.getRequestProgressObservable();
      this.getForecastCycles();
      
    });
  }

  getForecastCycles() {    
    this.forecastService.getForecastCycleList().subscribe((result) => {      
      result.sort((a, b) => {
        if(a.active === b.active) {
          return 0;
        } else {
          return a.active ? -1 : 1;
        }
      });       
      this.cycleList = result;     
      this.catalystCycleId = this.cycleList[0].catalystCycleId;
      this.fetchLatestInputOutput(this.cycleList[0].catalystCycleId);
    });
  }

  /**
   * getter that return periods FormArray
   * @returns periods fromArray
   */
  get periodsFormGroup() {
    if (this.inputForecastForm) return this.inputForecastForm.get('periods') as FormArray;
    return null;
  }

  /**
   * @function fetchLatestInputOutput to get saved data (last saved input result[0] and last saved output result[1]) and create predictionResults graphs and tables
   */
  fetchLatestInputOutput(cycleId:number): void {
    let lastDayOnStreamInTestRuns: number;
    let latestInputs: IForecast;
    this.forecastService.getLastInputOutput(cycleId).subscribe((results) => {
      if (results[0]) {
        this.inputForecastForm = this.forecastFormService.createForm(results[0]);        
        this.utilsExportService.setInputForExport(results[0]);

        latestInputs = results[0];
      } else {
        this.inputForecastForm = this.forecastFormService.createForm();
      }
      if (results[1]) {
        this.predictionResults = this.forecastMapperService.mapData(results[1]);
        this.forecastResult = results[1];
        lastDayOnStreamInTestRuns = this.forecastDataPeriodsService.getLastDayOnStreamInTestRuns(this.forecastResult);
        this.cycleLengthInDays = results[1]?.forecastedStates[results[1].forecastedStates.length - 1].dos;
        this.utilsExportService.setOutputForExport(results[1]);
      } else {
        this.predictionResults = undefined;
        this.forecastResult = undefined;
        this.cycleLengthInDays = 0;
      }

      this.periodDuration = this.forecastDataPeriodsService.getGraphPeriodObject(latestInputs);
      this.forecastDataPeriodsService.setPeriodDuration(this.periodDuration);
      const graphMarkData = this.forecastDataPeriodsService.createPeriodMarker(lastDayOnStreamInTestRuns, this.cycleLengthInDays);
      this.forecastDataPeriodsService.setGraphMarkData(graphMarkData);
      if(!this.isActiveCycle) {
        this.inputForecastForm?.disable();
      } else {
        this.inputForecastForm?.enable();
      }  
    });
  }

  /**
   * @function forecastInput called when submit the form then it calls the forecastOutput to trigger the cron and get Forecast result
   */
  forecastInput(): void {
    if (!this.inputForecastForm?.valid) {
      console.error('Invalid form');
      return;
    }

    this.forecastService.closeAllAccordions();

    this.validPredictionInputs = this.forecastService.getRequestInputFromForm(this.inputForecastForm, this.periodsFormGroup);

    this.forecastService.send(this.validPredictionInputs)?.subscribe({
      next: (result) => {
        if (result?.forecast_id) {
          this.forecastOutput(result?.forecast_id);
        }
      },
      error: (_) => {
        this.modalService.showErrorModal({ title: 'Failed to send the request.', type: 'error', message: 'The request failed. Try again later.' });
      },
    });
  }

  /**
   * @function forecastOutput trigger the cron and get Forecast result
   * @param forecast_id
   */
  forecastOutput(forecast_id: number): void {
    this.forecastService.getById(forecast_id).subscribe({
      next: (forecastResult: ForecastResults) => {
        if (forecastResult) {
          if (this.validPredictionInputs) {
            this.utilsExportService.setInputForExport(this.validPredictionInputs);
          }

          this.utilsExportService.setOutputForExport(forecastResult);
          this.extractDataFromForecastResults(forecastResult);
        }
      },
      error: (_) => {
        this.modalService.showErrorModal({ title: 'Failed to get forecast results.', type: 'error', message: 'The request failed. Try again later.' });
      },
    });
  }
  /**
   * @function isAccordionOpened it sets the status of accordionClicked (true or false) based on the event
   * @param event boolen
   */
  isAccordionOpened(event: boolean) {
    if (event) {
      this.accordionClicked = !this.accordionClicked;
    }
  }

  private extractDataFromForecastResults(forecastResult: ForecastResults): void {
    this.predictionResults = this.forecastMapperService.mapData(forecastResult);
    this.forecastResult = forecastResult;
    const lastIndex = forecastResult.forecastedStates.length - 1;
    this.cycleLengthInDays = forecastResult.forecastedStates[lastIndex].dos;
    const lastDayOnStreamInTestRuns = this.forecastDataPeriodsService.getLastDayOnStreamInTestRuns(this.forecastResult);
    if (this.validPredictionInputs) {
      this.periodDuration = this.forecastDataPeriodsService.getGraphPeriodObject(this.validPredictionInputs);

      this.forecastDataPeriodsService.setPeriodDuration(this.periodDuration);
    }
    const graphMarkData = this.forecastDataPeriodsService.createPeriodMarker(lastDayOnStreamInTestRuns, this.cycleLengthInDays);
    this.forecastDataPeriodsService.setGraphMarkData(graphMarkData);
  }

  getCycleLengthDate(date: string) {
    this.cycleLengthDate = date;
  }

  getStartRunDate(date:IForecastDisplayPreference | null) {
    this.startRunDate = date;
  }

  changeToDisplay(number: number) {
    this.simultaneousDisplay = number > 1;
    this.graphNumberTodisplay = number;
  }
  onImport(data: any) {
    if (data.Sheet1 instanceof Error) {
      this.modalService.showErrorModal({
        title: 'Error processing Excel data.',
        type: 'error',
        message: 'An error occurred while processing the Excel file. Please try again.',
      });
    } else {
      const importedData = this.forecastMapperService.mapImportedData(data);
      this.inputForecastForm = this.forecastFormService.createForm(importedData);
    }
  }
}
