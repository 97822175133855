<div id="cycle-length" class="card">
  <div class="card-body d-inline-flex justify-content-between">
    <span class="h4">Catalyst Cycle</span>

    <div class="form-group catalyst-cycle">      
      <select class="form-select" id="catalystCycle" [(ngModel)]="selectedCycle" (change)="onCycleChange()">        
        <option *ngFor="let data of cycleList" [ngValue]="data" [selected]="selectedCycle?.catalystCycleId === data?.catalystCycleId">{{data?.cycleName}}</option>
      </select>
    </div>
    <div class="d-flex gap-3">
      
      <div class="date-picker-container dates-of-forecast">     
        Start of cycle
        <input #startRunInput [ngStyle]="{'width': (startRunInput.value.length * 8)  + 'px'}" class="date-picker-input" matInput [matDatepicker]="picker" [value]="date" [min]="date" [max]="date" readonly="true">
        <mat-datepicker-toggle matIconSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon class="mat-icon-base-red date-picker-icon">calendar_today</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>     
   
      <div class="vl"></div>
      <div class="dates-of-forecast">End of forecast  <span>{{cycleLengthDate}}</span></div>
      <div class="vl"></div>

      <div id="export-data">
        <app-ui-export-btn></app-ui-export-btn>
      </div>
    </div>

  </div>
</div>
