import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';
import { Observable } from 'rxjs';
import { ENDPOINTS } from '../../shared/constants/endpoints';
import { IForecastDisplayPreference } from '../../types/forecast-display-preference';

@Injectable({
  providedIn: 'root',
})
export class ForecastDisplayPreferencesService {
  constructor(private httpService: HttpService) {}

  public send(data: IForecastDisplayPreference): Observable<any> {
    return this.httpService.send<IForecastDisplayPreference>(data, ENDPOINTS.forecastPreferencesEndpoint);
  }

  public get(cycleId:number): Observable<IForecastDisplayPreference> {
    return this.httpService.get<IForecastDisplayPreference>(ENDPOINTS.lastSavedForecastPreferencesEndpoint+'/'+cycleId);
  }
}
