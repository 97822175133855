import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';
import { ENDPOINTS } from 'src/app/shared/constants/endpoints';
import { BehaviorSubject, Observable, catchError, forkJoin, of } from 'rxjs';
import { IForecast } from 'src/app/types/forecast';
import { ForecastResults } from 'src/app/types/forecast-results';
import { FormArray, FormGroup } from '@angular/forms';
import { FlowersFile } from 'src/app/types/flowers-file';
import { ForecastCycle } from 'src/app/types/forecast-cycle';

@Injectable({
  providedIn: 'root',
})
export class ForecastService {
  private isAccordionsClosed: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private selectedCycle: BehaviorSubject<ForecastCycle | null> = new BehaviorSubject<ForecastCycle | null>(null);

  constructor(private httpService: HttpService) {}
  /**
   * function to send forecast inputs
   * @param data forecast inputs
   * @returns Observable
   */
  public send(data: IForecast): Observable<any> {
    return this.httpService.send<IForecast>(data, ENDPOINTS.forecastInputEndpoint);
  }

  /**
   * function to get forecast output by Id
   * @param id number
   * @returns Observable
   */
  public getById(forecast_id: number): Observable<ForecastResults> {
    return this.httpService.getById(ENDPOINTS.forecastOutputEndpoint, { paramName: 'forecast_id', id: forecast_id });
  }

  /**
   * function to get last saved input
   * @returns Observable
   */
  public getLastSavedInput(cycleId:number): Observable<any> {
    return this.httpService.get(ENDPOINTS.lastSavedInputEndpoint+'/'+cycleId).pipe(
      catchError(() => {
        return of(undefined);
      }),
    );
  }

  /**
   * function to get last saved output
   * @returns Observable
   */
  public getLastSavedOutput(cycleId:number): Observable<any> {
    return this.httpService.get(ENDPOINTS.lastSavedOutputEndpoint+'/'+cycleId).pipe(
      catchError(() => {
        return of(undefined);
      }),
    );
  }

  /**
   * function to get last flowers file
   * @returns Observable
   */
  public getLastFlowersFile(): Observable<FlowersFile> {
    return this.httpService.get(ENDPOINTS.lastFlowersFileEndpoint);
  }

   /**
   * function to get last flowers file
   * @returns Observable
   */
   public getForecastCycleList(): Observable<ForecastCycle[]> {
    return this.httpService.get(ENDPOINTS.forecastCycleList);
  }

  public getLastInputOutput(cycleId:number): Observable<[any, any]> {
    return forkJoin([this.getLastSavedInput(cycleId), this.getLastSavedOutput(cycleId)]);
  }

  /**
   * @function getRequestInputFromForm
   * @param form
   * @param periodsForm
   * @returns IForecast object as forecastInput filled in by the user
   */
  public getRequestInputFromForm(form: FormGroup<any>, periodsForm: FormArray | null): IForecast {
    const periods = [];
    if (periodsForm) {
      for (const period of periodsForm.controls) {
        let cco = undefined;
        if (period.value.hasChangeOut) {
          cco = {
            durationInDays: period.value.changeOutOptions.durationInDays,
            mocDemetCat: period.value.changeOutOptions.mocDemetCat,
            mocMainCat: period.value.changeOutOptions.mocMainCat,
            cokeBuildUp: period.value.changeOutOptions.cokeBuildUp,
          };
        }
        periods.push({
          duration: period.value.duration,
          d15: period.value.d15,
          svalue: period.value.svalue,
          ccr: period.value.ccr,
          nvalue: period.value.nvalue,
          t50: period.value.t50,
          evap370: period.value.evap370,
          metals: period.value.metals,
          flowRate: period.value.flowRate,
          ppH2: period.value.ppH2,
          h2HC: period.value.h2Hc,
          catalystVolume: period.value.catalystVolume,
          maxWABT: period.value.maxWabt,
          tlpSulfurTarget: period.value.tlpSulfurTarget,
          changeOutOptions: cco,
        });
      }
    }
    return {
      simulCOP: periods,
      cc: {
        act: {
          conv: form.value.actConv,
          hds: form.value.actHds,
          hdn: form.value.actHdn,
        },
        absC: {
          conv: form.value.abscConv,
          hds: form.value.abscHds,
          hdn: form.value.abscHdn,
        },
        absM: {
          conv: form.value.absmConv,
          hds: form.value.absmHds,
          hdn: form.value.absmHdn,
        },
      },
    };
  }

  /**
   * @function closeAllAccordions forces all accordions that contain forecast inputs to close
   */
  closeAllAccordions() {
    this.isAccordionsClosed.next(false);
  }
  /**
   *
   * @returns Observable with the forecast parameters accordion status isAccordionsClosed
   */
  getCloseAllAccordions() {
    return this.isAccordionsClosed.asObservable();
  }

  setSelectedCycle(isActive: ForecastCycle) {
    this.selectedCycle.next(isActive);
  }

  getSelectedCycle() {
    return this.selectedCycle.asObservable();
  }
}
