export const ENDPOINTS = {  
  forecastInputEndpoint: '/v1/forecast/request-forecast',
  forecastPreferencesEndpoint: '/v1/forecast/forecast-preferences',
  lastSavedForecastPreferencesEndpoint: '/v1/forecast/latest/forecast-preferences',
  forecastOutputEndpoint: '/v1/forecast/forecast-result',
  lastSavedInputEndpoint: '/v1/forecast/latest/inputs',
  lastSavedOutputEndpoint: '/v1/forecast/latest/outputs',
  lastFlowersFileEndpoint: '/v1/flowers/latest/flowers-file',
  parametersConfig: '/v1/configuration/site-configuration',
  forecastCycleList: '/v1/catalyst-cycle/cycle-list',
};
